import styled from "styled-components"
import { BodyXLMediumSecondary, BodyXLSecondary } from "../../styled-components/Font"

export const AboutProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  @media screen and (max-width: 1024px) {
    gap: 40px;
  }
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`
export const AboutProductGridCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const AboutText = styled.div`
  display: flex;
  flex-direction: column;
  ${BodyXLMediumSecondary} {
    display: none;
  }
  ${BodyXLSecondary}:first-child {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1024px) {
    ${BodyXLMediumSecondary} {
      display: initial;
      margin-top: 40px;
    }
    ${BodyXLSecondary}:first-child {
      margin-bottom: 20px;
    }

    
  }
`
