

export const BASE_URL = "https://air-bot.deck.lc";
// export const BASE_URL = "http://localhost:3001";

export interface IForm {
   form: {
       name?: string
       phone?: string
       company?: string
       type: string
       email: string
   }
}
//@ts-ignore
const handleResponse = (response) => {
    if (response && response.ok) {
        return response
    } else {
        Promise.reject(`Ошибка ${response.status}`)
    };
}
//@ts-ignore
const handleError = (response) => {
    console.log(response)
}

export function sendForm({form}: IForm) {
    return fetch(
        `${BASE_URL}/telegram`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        }
    ).then(handleResponse).catch(handleError);
}

export function sendFile(file: File) {

    const formData = new FormData();
    formData.append('file', file);
    return fetch(
        `${BASE_URL}/telegram/file`, {
            method: 'POST',
            body: formData
        }
    ).then(handleResponse).catch(handleError);
}
