import {HeadlineL, HeadlineL2} from "../../styled-components/Font";
import {ImageContainer, ImagePopup, PopupContainer, PopupText} from "../../styled-components/Content";
import {functions, FunctionText} from "./text";
import {useEffect, useRef, useState} from "react";
import {TabsEnum} from "./enum";
import useOnclickOutside from "react-cool-onclickoutside";
import {Section, SectionRightBlock, Separator, Title} from "../../styled-components/Base";
import {Tab, TabActive, Tabs, TabsContent, TabsLeftBlock} from "../../styled-components/Tabs";
import useOnScreen from "../../hooks/useOnScreen";

export default function Function() {

    const [activeTab, setActiveTab] = useState(TabsEnum.Modules)
    const [imagePopupOpen, setImagePopupOpen] = useState(false)
    const [touchX, setTouchX] = useState(0)
    const currentTab = functions.find(i => i.tab === activeTab)
    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)

    const popupRef = useOnclickOutside(() => {
        if (imagePopupOpen) setImagePopupOpen(false)
    });

    const nextTabHandler = (forward: boolean) => {

        const currentIndex = functions.findIndex(i => i.tab === activeTab)

        if (forward) {
            if (currentIndex === (functions.length - 1)) {
                setActiveTab(functions[0].tab)
            } else {
                setActiveTab(functions[currentIndex + 1].tab)
            }
        } else {
            if (currentIndex === 0) {
                setActiveTab(functions[functions.length - 1].tab)
            } else {
                setActiveTab(functions[currentIndex - 1].tab)
            }
        }
    }

    useEffect(() => {

        const arrowHandler = (e: KeyboardEvent) => {
            if (imagePopupOpen) {
                if (e.key === 'ArrowRight') {
                    e.preventDefault()
                    nextTabHandler(true)
                }
                if (e.key === 'ArrowLeft') {
                    e.preventDefault()
                    nextTabHandler(false)
                }
            }
        }

        document.addEventListener("keydown", arrowHandler);

        return () => document.removeEventListener("keydown", arrowHandler);
    }, [activeTab, imagePopupOpen]);

    useEffect(() => {
        const tab = functions.find(i => i.tab === activeTab)
        const tabs =  document.querySelector('.tabs')
        if (tabs && tab) {
            tabs.scrollLeft = tab.scrollX
        }
    }, [activeTab]);

    useEffect(() => {
        if (visible) setIsVisible(true)
    }, [visible])

    return <><Separator className={isVisible? 'animation4' : ''}/>
        <Section className={'function'}>
            <Title style={{maxWidth: '900px'}}>
                <HeadlineL className={isVisible? 'animation1' : ''}>
                    {FunctionText.title}
                </HeadlineL>
                <HeadlineL className={isVisible? 'animation1' : ''}>
                    {FunctionText.title1}
                </HeadlineL>
            </Title>

        <TabsContent>
            <TabsLeftBlock className={isVisible? 'animation2 tabs' : 'tabs'}>
                <Tabs>
                    {functions.map((item, index) => {
                        return <Tab key={index} as={activeTab === item.tab ? TabActive : Tab}
                                    onClick={() => setActiveTab(item.tab)}>
                            {item.tab}
                        </Tab>
                    })}
                </Tabs>
            </TabsLeftBlock>
            <SectionRightBlock className={isVisible? 'animation2' : ''}>
                <ImageContainer
                    ref={ref}
                    onTouchEnd={(e) => {
                        if (e.changedTouches[0].screenX - touchX > 100) {
                            nextTabHandler(false)
                        }
                        if (touchX - e.changedTouches[0].screenX > 100) {
                            nextTabHandler(true)
                        }
                }}
                    onTouchStart={(e) => {
                        setTouchX(e.changedTouches[0].screenX)
                    }}
                    onClick={() => setImagePopupOpen(true)}>
                    {currentTab
                    && <img src={currentTab.image} alt={'function description'}/>}
                </ImageContainer>
            </SectionRightBlock>
            <ImagePopup className={imagePopupOpen ? 'visible' : 'hidden'}>
                {currentTab
                && <PopupContainer ref={popupRef}>
                    <img src={currentTab.image}
                         alt={'function description'}/>
                    <PopupText>
                        <span onClick={() => nextTabHandler(false)}>{'❮'}</span>
                        <p>{currentTab?.text || ''}</p>
                        <span onClick={() => nextTabHandler(true)}>{'❯'}</span>
                    </PopupText>

                </PopupContainer>}

            </ImagePopup>
        </TabsContent>
    </Section></>
}
