export enum TabsEnum{
    Modules = 'Справочники',
    Planning = 'Долгосрочное планирование',
    ResourcePlanning = 'Управление ресурсами',
    Platform = 'Интерактивный перрон',
    Baggage = 'Обработка и сортировка багажа',
    Fids = 'FIDS',
    AdminApp = 'Приложение для руководства',
    EmployeeApp = 'Приложение для сотрудников',
    PassengersApp = 'Приложение для пассажиров'
}
