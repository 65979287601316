import styled from "styled-components";

export const MainSection = styled.div`
  padding: 100px 0 200px;
  @media screen and (max-width: 1024px) {
    padding: 60px 0 120px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 0 60px;
  }
`
export const Maintext = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 370px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 40px;
  }
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`
export const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 40px;
  }
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

export const MainImageContainer = styled.div`
  width: 100%;
  padding-top: 100px;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 80px;
    width: 100vw;
    transform: translateX(-16px);
    img {
      max-width: 100vw;
      
      width: 100vw;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }
`