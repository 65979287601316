import {Form, FormInput, FormLabel} from "../../styled-components/Form";
import {DisabledPrimaryButtonXl, PrimaryButtonXl} from "../../styled-components/Button";
import {AddFile, AddFileContainer, AddFileLabel, ContactCell, ContactGrid} from "./Styled";
import {Content, SectionLeftBlock, SectionRightBlock} from "../../styled-components/Base";
import React, {useState} from "react";
import {Clear} from "../../icons/Icons";
import {sendFile, sendForm} from "../../utils/sendForm";
import NumberFormat from 'react-number-format';

export interface ContactFormProps {
    visible: boolean
    setPopupText: (text: string) => void
}

export default function ContactForm({visible, setPopupText}: ContactFormProps) {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [formFile, setFormFile] = useState<File | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    const [emailError, setEmailError] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    const [emailErrorVisible, setEmailErrorVisible] = useState(false)
    const [nameErrorVisible, setNameErrorVisible] = useState(false)
    const [phoneErrorVisible, setPhoneErrorVisible] = useState(false)

    const labelStyle = {
        fontSize: '10px',
        transform: 'translateY(-20px)'
    }


    return <Content className={visible ? "animation2" : ''}>
        <SectionLeftBlock/>
        <SectionRightBlock>
            <Form>
                <ContactGrid>
                    <ContactCell>
                        <FormInput
                            className={nameErrorVisible && nameError ? 'underline-error' : 'underline'}
                            onFocus={() => setNameErrorVisible(false)}
                            onBlur={() => setNameErrorVisible(true)}
                            onChange={e => {
                                setName(e.currentTarget.value)
                                !e.currentTarget.validity.valid
                                    ? e.currentTarget.validationMessage.includes('указанном')
                                        ? setNameError('Поле может содержать только буквы.')
                                        : e.currentTarget.validationMessage.includes('символ')
                                            ? setNameError('Введите минимум 2 символа.')
                                            : setNameError(e.currentTarget.validationMessage)
                                    : setNameError('')
                            }}
                            required
                            pattern={'[a-zA-Z\u0400-\u04ff-\\s]+'}
                            maxLength={150}
                            minLength={2}
                            value={name}
                            id={'name'}/>
                        <FormLabel
                            style={name.length > 0 ? labelStyle : {}}
                            htmlFor="name">Ваше Имя</FormLabel>
                        <span style={nameErrorVisible ? {display: "block"} : {}}>{nameError}</span>
                    </ContactCell>
                    <ContactCell>
                        <NumberFormat
                            className={`${phoneErrorVisible && phoneError ? 'underline-error' : 'underline'} numberInput`}
                            id={'phone'}
                            type={'tel'}
                            format={'+#(###)###-##-##'}
                            mask={'_'}
                            onFocus={() => setPhoneErrorVisible(false)}
                            onBlur={() => setPhoneErrorVisible(true)}
                            onValueChange={(value) => {
                                let inputValue
                                if (value.value.substring(0,1) === '8') {
                                    inputValue = '+7' + value.formattedValue.substring(2, value.formattedValue.length)
                                } else inputValue = value.formattedValue
                                if (inputValue.includes('_'))  {
                                    setPhoneError('Введите корректный номер телефона.')
                                } else { setPhoneError('')
                                }
                                if (inputValue.length === 0)  {
                                    setPhoneError('Введите номер телефона.')
                                }
                                setPhone(inputValue)
                            }}
                            value={phone}
                            title={'Введите номер телефона'}
                            required
                        />
                        <FormLabel
                            style={phone.length > 0 ? labelStyle : {}}
                            htmlFor="phone">Номер телефона</FormLabel>
                        <span style={phoneErrorVisible ? {display: "block"} : {}}>{phoneError}</span>
                    </ContactCell>
                    <ContactCell>
                        <FormInput
                            className={'underline'}
                            onChange={e => setCompany(e.currentTarget.value)}
                            value={company}
                            maxLength={150}
                            title={'Введите название вашей компании'}
                            id={'company'}/>
                        <FormLabel
                            style={company.length > 0 ? labelStyle : {}}
                            htmlFor="company">Название компании</FormLabel>
                    </ContactCell>
                    <ContactCell>
                        <FormInput
                            className={emailErrorVisible && emailError ? 'underline-error' : 'underline'}
                            onFocus={() => setEmailErrorVisible(false)}
                            onBlur={() => setEmailErrorVisible(true)}
                            onChange={e => {
                                setEmail(e.currentTarget.value)
                                !e.currentTarget.validity.valid
                                    ? setEmailError(`Введите корректный e-mail.`)
                                    : setEmailError('')
                            }}
                            value={email}
                            required
                            maxLength={100}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            id={'email'}
                            type={'email'}/>
                        <FormLabel
                            style={email.length > 0 ? labelStyle : {}}
                            htmlFor="email">E-mail</FormLabel>
                        <span style={emailErrorVisible ? {display: "block"} : {}}>{emailError}</span>
                    </ContactCell>
                    <ContactCell>
                        <AddFileContainer>
                            <AddFile
                                type={'file'}
                                accept=".jpg, .jpeg, .png, .pdf, .doc, .xlsx, .pptx"
                                id={'file'}
                                onChange={(e) => {
                                    if (e.currentTarget.files) {
                                        setFormFile(e.currentTarget.files[0])
                                    }
                                }
                                }/>
                            <AddFileLabel
                                htmlFor="file">
                                {formFile ? formFile.name : 'Прикрепить файл'}
                            </AddFileLabel>
                            {formFile && <Clear onClick={() => {
                                setFormFile(undefined)
                                const fileInput = document.getElementById('file')
                                if (fileInput) {
                                    (fileInput as HTMLInputElement).value = ''
                                }
                            }}/>}
                        </AddFileContainer>
                    </ContactCell>
                    <ContactCell>
                        <PrimaryButtonXl
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault()

                                const formFetch = () => sendForm({
                                    form: {
                                        name: name,
                                        phone: phone,
                                        company: company,
                                        email: email,
                                        type: "Хотят связаться",
                                    }
                                }).then((res) => {
                                    if (res.ok) {
                                        setPopupText('Ваша заявка отправлена, наш менеджер свяжется с вами')
                                        setFormFile(undefined)
                                        setCompany('')
                                        setName('')
                                        setPhone('')
                                        setEmail('')
                                        setEmailErrorVisible(false)
                                        setNameErrorVisible(false)
                                        setPhoneErrorVisible(false)
                                        const fileInput = document.getElementById('file')
                                        if (fileInput) {
                                            (fileInput as HTMLInputElement).value = ''
                                        }

                                    }
                                }).catch(() => {
                                    setPopupText('Произошла ошибка при отправке, попробуйте еще раз.')
                                }).finally(() => setLoading(false))

                                if (formFile) {
                                    setLoading(true)
                                    sendFile(formFile)
                                        .then(() => {
                                            formFetch()
                                        })
                                        .catch(() => {
                                            setPopupText('Произошла ошибка при отправке файла.')
                                        }).finally(() => setLoading(false))
                                } else {
                                    setLoading(true)
                                    formFetch()
                                }
                            }
                            }
                            as={
                                nameError
                                || emailError
                                || phoneError
                                || name === ''
                                || email === ''
                                || phone === ''
                                || loading
                                    ? DisabledPrimaryButtonXl
                                    : PrimaryButtonXl}
                        >
                            {loading ? 'Отправка...' : 'Отправить заявку'}
                        </PrimaryButtonXl>
                    </ContactCell>
                </ContactGrid>
            </Form>
        </SectionRightBlock>

    </Content>
}
