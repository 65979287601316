import {
    HeaderContainer,
    HeaderLink,
    HeaderLinkActive,
    HeaderLinks,
    HeaderRow,
    MobileSeparator,
    ToTop
} from "../styled-components/Header";
import {PrimaryButton} from "../styled-components/Button";
import {Logo} from "../icons/Icons";
import {scrollTo} from "../utils/scrollTo";
import React, {useEffect, useState} from "react";
import {LinkMUpperCase} from "../styled-components/Font";
import { Separator } from "../styled-components/Base";


export default function Header() {

    const [menuOpened, setMenuOpened] = useState(false)

    useEffect(() => {
        const closeMenuOnResize = () => {
            if (document.documentElement.clientWidth > 1023) setMenuOpened(false)
        }
        window.addEventListener('resize', closeMenuOnResize)
        return () => window.removeEventListener('resize', closeMenuOnResize)
    },[])

    return <HeaderContainer className={menuOpened ? "header menu-opened animation2": 'header menu-closed animation2'}>
        <Logo/>
        <HeaderRow className={menuOpened ? "menu-opened-row" : 'menu-closed-row'}>
            {menuOpened && <MobileSeparator/>}
            <HeaderLinks>
            <HeaderLink
                onClick={() => {
                    setMenuOpened(false)
                    scrollTo(".about")
                }}>
                О&nbsp;продукте
            </HeaderLink>
            <HeaderLink
                onClick={() => {
                    setMenuOpened(false)
                    scrollTo(".clients")
                }}>
                Клиенты
            </HeaderLink>
            <HeaderLink
                onClick={() => {
                    setMenuOpened(false)
                    scrollTo(".business")
                }}>
                Преимущества
            </HeaderLink>
            <HeaderLink
                onClick={() => {
                    setMenuOpened(false)
                    scrollTo(".function")
                }}>
                Функции
            </HeaderLink>
            </HeaderLinks>
            {menuOpened && <HeaderLinkActive
                onClick={() => {
                    setMenuOpened(false)
                    scrollTo(".contact")
                }}>
                Связаться с нами
            </HeaderLinkActive>}
            <PrimaryButton
                className={"menu-contact-button"}
                onClick={() => {
                    scrollTo(".contact")
                }}>
                Связаться с нами
            </PrimaryButton>
        </HeaderRow>

        <LinkMUpperCase className={'menu-switch'}
                        onClick={() => setMenuOpened(!menuOpened)}>
            {menuOpened ? 'Закрыть' : 'Меню'}
        </LinkMUpperCase>
    </HeaderContainer>
}

