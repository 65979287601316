import {IText} from "../../interface";
import {TabsEnum} from "./enum";

export interface IFunction{
    tab : TabsEnum,
    image : string
    text : string
    scrollX : number
}

export const FunctionText : IText = {
    title : "Основные ",
    title1 : "функции",
}

export const functions : IFunction[] = [
    {
        tab: TabsEnum.Modules,
        image: "images/Modules.png",
        text: `Хранение и поддержание в актуальном состоянии всей
             нормативно-справочной информации, необходимой для работы аэропорта`,
        scrollX : 0
    },
    {
        tab: TabsEnum.Planning,
        image: "images/Planning.png",
        text: `Долгосрочное планирование человеческих
            и материальных ресурсов с учётом индивидуальных электронных 
            Технологических Графиков Обслуживания воздушных судов для авиакомпаний,
             доступности ресурсов и сезонного расписания`,
        scrollX : 100
    },
    {
        tab: TabsEnum.ResourcePlanning,
        image: "images/ResourcePlanning.png",
        text: `Краткосрочное планирование и оперативный контроль материальных 
            и человеческих ресурсов с их оптимальным распределением на сгенерированные задачи
             с учётом динамических факторов за счёт применения интеллектуального программного алгоритма`,
        scrollX : 350
    },
    {
        tab: TabsEnum.Platform,
        image: "images/Platform.png",
        text: `Отражение в реальном времени на Интерактивной Схеме всех воздушных судов,
             сотрудников и техники, находящихся на перроне, управление состоянием мачт освещения
              и стационарных источников питания, контроль скоростного режима и входа в запретные зоны`,
        scrollX : 550
    },
    {
        tab: TabsEnum.Baggage,
        image: "images/Baggage.png",
        text: `Обработка и сортировка багажа с использование мобильных сканеров на основании поступающих BSM сообщений по стандарту IATA RP1745`,
        scrollX : 800
    },
    {
        tab: TabsEnum.Fids,
        image: "images/Fids.png",
        text: `Трансляция необходимого информационного и рекламного контента на мониторы
             через защищённые алгоритмы вывода с возможностью удалённого управления их состоянием`,
        scrollX : 1000
    },
    {
        tab: TabsEnum.AdminApp,
        image: "images/AdminApp.png",
        text: `Отражение данных по динамике ключевых показателей аэропорта в графическом виде
             с возможностью их просмотра и сравнения за любой интересующий период,
              просмотр интерактивной карты и детальной информации по обслуживанию каждого рейса`,
        scrollX : 1150
    },
    {
        tab: TabsEnum.EmployeeApp,
        image: "images/EmployeeApp.png",
        text: `Отправка сотрудникам персональных задач на смену, оперативное информирование об изменениях 
            в обслуживании, фиксирование факта выполнения каждой из задач, внесение и просмотр
             коммерческой загрузки, формирование и подписание листа учёта услуг`,
        scrollX : 1400
    },
    {
        tab: TabsEnum.PassengersApp,
        image: "images/PassengersApp.png",
        text: `Получение актуальной информации по рейсам, в том числе в виде push-уведомлений,
             функции оплаты парковки, бизнес-залов и гостиницы, построения маршрута внутри 
             терминалов на интерактивной схеме`,
        scrollX : 1700
    },
]