export const Logo = () => <svg width="122" height="29" viewBox="0 0 122 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_20_188)">
        <path d="M0 28.3512V0.689713H7.047C9.396 0.689713 12.2513 0.770832 14.337 1.98762C16.848 3.46803 17.2529 6.16524 17.2529 8.82188V21.1114C17.2125 23.8694 16.362 25.9177 14.499 27.094C12.7979 28.2093 10.7325 28.3918 8.748 28.3918H0V28.3512ZM6.48 23.3827C10.8337 23.687 10.8743 21.9022 10.7528 18.2114V9.16664C10.7123 5.92188 9.639 5.73936 6.48 5.65825V23.3827Z" fill="black"/>
        <path d="M19.2793 28.3512V0.689713H34.1225V6.22608H25.7592V11.3163H33.8593V16.8526H25.7592V22.8352H34.568V28.3512H19.2793Z" fill="black"/>
        <path d="M47.264 10.3629V8.5986C47.264 6.24615 46.4136 5.02937 45.1986 5.02937C43.1736 4.98881 42.6876 6.1042 42.6876 7.90909L42.6471 21.5979C42.6471 23.1392 43.2748 24.0721 44.9758 24.0721C47.1831 24.0721 47.3653 21.7805 47.3653 20.0972V17.542H53.8453V20.9287C53.8453 25.6944 51.1116 29 44.4898 29C38.516 29 36.2075 25.816 36.2075 20.1783V8.82168C36.2075 2.7986 38.9819 0 44.8948 0C50.4231 0 53.7441 2.35244 53.7441 8.01049V10.3629H47.264Z" fill="black"/>
        <path d="M55.6685 28.3512V0.689713H62.1687V11.7624H62.2295L67.0287 0.689713H73.6505L67.7577 12.8981L74.0352 28.3512H67.1705L63.6065 18.7387L62.1687 21.5372V28.3512H55.6685Z" fill="black"/>
        <path d="M80.8371 0.689713H88.9978L94.0198 28.3512H87.56L86.9931 23.4233H82.6798L82.0521 28.3512H75.896L80.8371 0.689713ZM83.5708 18.5358H86.7501L85.2718 4.98901H85.211L83.5708 18.5358Z" fill="#59B1FF"/>
        <path d="M95.9229 0.689728H102.261L102.261 3.48558V6.00301V28.3716H95.9229V6.00301V3.76442L95.9229 0.689728Z" fill="#59B1FF"/>
        <path d="M104.295 0.697109H108.512H112.729C118.183 0.547038 120.877 2.70162 120.812 7.42584C120.836 9.08263 120.625 10.4633 120.18 11.5618C119.67 12.6483 118.592 13.4647 116.94 14.0109V14.083C119.348 14.6292 120.59 16.1359 120.672 18.6031V23.0698C120.672 24.0423 120.695 25.0507 120.736 26.0952C120.801 27.1397 120.988 27.9201 121.298 28.4423H115.02C114.691 27.9201 114.487 27.1397 114.393 26.0952C114.304 25.0507 114.27 24.0423 114.294 23.0698V19.0713C114.294 18.2369 114.118 17.6306 113.766 17.2465C113.392 16.8863 112.747 16.7062 111.839 16.7062H110.662V28.4423H104.289L104.289 0.697109H104.295ZM110.674 11.874H112.243C113.315 11.874 113.965 11.5438 114.205 10.8834C114.387 10.2592 114.469 9.4668 114.446 8.51835C114.446 8.02013 114.434 7.57592 114.41 7.17973C114.387 6.77154 114.299 6.44739 114.135 6.20728C113.989 5.93114 113.736 5.72104 113.385 5.58298C113.046 5.45692 112.553 5.39089 111.921 5.39089H110.679V11.874H110.674Z" fill="#59B1FF"/>
    </g>
    <defs>
        <clipPath id="clip0_20_188">
            <rect width="121.298" height="29" fill="white"/>
        </clipPath>
    </defs>
</svg>



export const Close = (props: { onClick: () => void }) => <svg onClick={props.onClick} cursor={'pointer'} width="30"
                                                              height="30" viewBox="0 0 30 30" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg">
    <path d="M1.44362 28.8844L28.7059 1.62183L1.44362 28.8844ZM1.44362 1.62183L28.7059 28.8844L1.44362 1.62183Z"
          stroke="#989898" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const Clear = (props: { onClick: () => void }) => <svg onClick={props.onClick} cursor={'pointer'} width="12"
                                                              height="12" viewBox="0 0 12 12" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg">
    <path d="M2 10L10 2L2 10ZM2 2L10 10L2 2Z" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>
