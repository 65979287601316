import {HeadlineL} from "../../styled-components/Font";
import {Aaq, Aer, Kazan, Krr, Sip, Vvo} from "../../icons/Clients";
import {Section, Separator} from "../../styled-components/Base";
import {ClientsGrid, ClientsGridCell} from "./Styled";
import useOnScreen from "../../hooks/useOnScreen";
import {useEffect, useRef, useState } from "react";

export default function Clients() {

    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (visible) setIsVisible(true)
    }, [visible])

    return <><Separator className={isVisible? 'animation4' : ''}/>
        <Section  className={isVisible? "clients animation2" : 'clients'}>
        <HeadlineL  className={isVisible? 'animation1' : ''}>
            Наши клиенты
        </HeadlineL>

        <ClientsGrid ref={ref}>
            <ClientsGridCell>
                <Kazan/>
            </ClientsGridCell>
            <ClientsGridCell>
                <Krr/>
            </ClientsGridCell>
            <ClientsGridCell>
                <Aer/>
            </ClientsGridCell>
            <ClientsGridCell>
                <Aaq/>
            </ClientsGridCell>
            <ClientsGridCell>
                <Sip/>
            </ClientsGridCell>
            <ClientsGridCell>
                <Vvo/>
            </ClientsGridCell>
        </ClientsGrid>
    </Section></>
}
