import styled from "styled-components";

export const HeaderContainer = styled.div`
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
  @media screen and (max-width: 1024px) {
    padding: 22px 0;
  }
`
export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`
export const HeaderLink = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media screen and (max-width: 480px) {
    font-size: 28px;
    line-height: 38px;
  }

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }
`

export const HeaderLinks = styled.div`
  display: flex;
  gap: 40px;
  @media screen and (max-width: 1400px) {
    gap: 30px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    padding: 60px 0 20px;
    gap: 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 0 15px;
    gap: 15px;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 0 10px;
    gap: 10px;
  }
`

export const HeaderLinkActive = styled(HeaderLink)`
  color: #59B1FF;
  padding-bottom: 300px;
`
export const MobileSeparator = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 80px;
  @media screen and (max-width: 768px) {
    height: 70px;
  }
`

export const ToTop = styled.div`
  visibility: hidden;
  position: fixed;
  z-index: 5;
  cursor: pointer;
  right: 50px;
  bottom: 100px;
  width: 110px;
  padding: 20px 10px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(255, 255, 255, .5);
  backdrop-filter: blur(5px) opacity(.8);

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }

  span {
    font-family: "SymbolaRegular", sans-serif;
    font-size: 24px;
    margin-right: 3px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`
