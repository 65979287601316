import styled from "styled-components"

export const BusinessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  @media screen and (max-width: 1024px) {
    gap: 40px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`
export const BusinessGridCell = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  border-top: 1px solid #CCCCCC;
  @media screen and (max-width: 1024px) {
    padding-top: 22px;
  }
`
export const BusinessTitleContainer = styled.div`

`
export const BusinessCellTitle = styled.div`
  padding: 20px 0 16px;
  @media screen and (max-width: 768px) {
    padding: 11px 0 5px;
  }
`
